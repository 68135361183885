import { ReactComponent as WaterProof } from "../assets/Images/Waterproof.svg";
import { ReactComponent as Recycle } from "../assets/Images/recycling.svg";
import { ReactComponent as BioPolymer } from "../assets/Images/Biopolymer.svg";
import { ReactComponent as Global } from "../assets/Images/global.svg";
import { ReactComponent as Vegon } from "../assets/Images/vegon.svg";
import { ReactComponent as GRS } from "../assets/Images/GRS.svg";

export const authToken = "";
export const baseUrl = process.env.REACT_APP_URL;

export const propertyList = [
  {
    productList: "GRS",
    component: GRS,
  },
  {
    productList: "Vegan",
    component: Vegon,
  },
  {
    productList: "Waterproof",
    component: WaterProof,
  },
  {
    productList: "Recyclable",
    component: Recycle,
  },
  {
    productList: "BioPolymer",
    component: BioPolymer,
  },
  {
    productList: "Sustainable",
    component: Global,
  },
];
export const AdhesiveList = [
  "no adhesive",
  "one side adhesive",
  "both side adhesive",
];
export const areArraysEqual = (arr1: number[], arr2: number[]) =>
  arr1?.length === arr2?.length &&
  arr1?.every((element, index) => element === arr2?.[index]);
export const defaultValueForThickness = [0, 4.0];
export const defaultValueForHardness = [0, 1];
export const defaultValueForTemperature = [60, 140];
export const componentValueArray = [
  "Interlining/Eyelet",
  "Toe Puff",
  "Counter",
  "Leather goods",
];
export const batchSize = 10;

// product name sorting

export function sortByProductNameAsc(a: any, b: any): number {
  const nameA: string = a.attributes.MaterialNameApp.toUpperCase();
  const nameB: string = b.attributes.MaterialNameApp.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export function sortByNameDesc(a: any, b: any): number {
  return sortByProductNameAsc(b, a);
}
export const extractNumberFromString = (str: string) => {
  const match = str?.match(/\d+(\.\d+)?/);
  return match ? parseFloat(match[0]) : 0;
};
export function findMinMaxThickness(arrayOfObjects: any) {
  if (!Array.isArray(arrayOfObjects) || !arrayOfObjects.length) {
    return { min: 0, max: 0 };
  }

  let min = Infinity;
  let max = -Infinity;

  for (const obj of arrayOfObjects) {
    min = Math.min(min, obj.attributes.ThicknessMin);
    max = Math.max(max, obj.attributes.ThicknessMax);
  }

  return { min, max };
}
export function formatDecimal(number: any) {
  var formattedNumber = parseFloat(number).toFixed(2);
  return formattedNumber;
}
export function isIphone() {
  return /iPhone/.test(navigator.userAgent) && !(window as any).MSStream;
}

function mapResponseToSliderStep(
  responseValue: number,
  fullRangeMin: number,
  fullRangeMax: number,
  stepSize: number,
  roundUp: boolean
): number {
  // Normalize the response value to a 0-1 scale
  const normalizedValue =
    (responseValue - fullRangeMin) / (fullRangeMax - fullRangeMin);

  // Scale the normalized value to the slider range
  const scaledValue = normalizedValue * fullRangeMax;

  // Snap the scaled value to the nearest or next step
  const snappedValue = roundUp
    ? Math.ceil(scaledValue / stepSize) * stepSize
    : Math.floor(scaledValue / stepSize) * stepSize;

  // Ensure the snapped value is within the slider range
  return Math.max(fullRangeMin, Math.min(snappedValue, fullRangeMax));
}

export function getSliderValues(
  responseMin: number,
  responseMax: number,
  fullRangeMin: number,
  fullRangeMax: number,
  stepSize: number
): number[] {
  const minSliderValue =
    responseMin < stepSize
      ? 0
      : mapResponseToSliderStep(
          responseMin,
          fullRangeMin,
          fullRangeMax,
          stepSize,
          false
        );
  const maxSliderValue = mapResponseToSliderStep(
    responseMax,
    fullRangeMin,
    fullRangeMax,
    stepSize,
    true
  );
  const fixedSliderValues: number[] = [minSliderValue, maxSliderValue].map(
    (value) => parseFloat(value.toFixed(1))
  );
  return fixedSliderValues;
}
export const extractTextFromModuleText = (text:string) =>{
let keyword = "module";
let startIndex = text?.indexOf(keyword) + keyword?.length;
let result = text?.slice(startIndex)?.trim();
return result;
}