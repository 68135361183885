// App.js
import React, { lazy, useEffect, useRef, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { getApiCall } from "./api/dashBoardApi";

const AppLayout = lazy(() => import("./AppLayout") as any);
const ListViewFrame = lazy(
  () => import("./components/PDF/listViewFrame") as any
);
const ProductDetailPdf = lazy(
  () => import("./components/PDF/ProductDetailsPDF") as any
);
const ProductDetailPreviewPdf = lazy(
  () => import("./components/PDF/ProductDetailsPDF") as any
);
function App() {
  const initializedRender = useRef(false);
  const [globalMenu, setGobalMenu] = useState<any>({});
  const getDataFromLocalStorage = JSON.parse(
    (window as any).localStorage.getItem("products")
  );
  const fetchData = async (url: any, setter: any) => {
    try {
      const response = await getApiCall(url);
      if (response) {
        setter(response.data[0]?.attributes || []);
        appendCookiebotScript(
          response.data[0]?.attributes?.global_tracking?.data?.attributes
            ?.CookiebotID
        );
        appendGoogleAnalytics(
          response.data[0]?.attributes?.global_tracking?.data?.attributes
            ?.GoogleAnalyticsID
        );
      } else {
        setter({});
      }
    } catch (error) {
      console.error(`Error fetching ${url} data:`, error);
    }
  };

  useEffect(() => {
    if (!initializedRender.current && window.navigator.onLine) {
      initializedRender.current = true;
      fetchData("/globals?populate=deep", setGobalMenu);
    }
  }, []);

  const appendCookiebotScript = (cbid:any) => {
    // Check if the script is already appended to avoid duplicating it
    if (!document.getElementById("Cookiebot")) {
      const script = document.createElement("script");
      script.id = "Cookiebot";
      script.src = `https://consent.cookiebot.com/uc.js`;
      script.type = "text/javascript";
      script.dataset.cbid = cbid;
      script.dataset.blockingmode = "auto";
  
      // Error handling for script loading
      script.onerror = () => {
        console.error("Failed to load the Cookiebot script.");
      };
  
      // Append script to body when the DOM is fully loaded
      if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", () => {
          document.body.appendChild(script);
        });
      } else {
        document.body.appendChild(script);
      }
    } else {
      console.log("Cookiebot script is already appended.");
    }
  };

  const appendGoogleAnalytics = (fetchedGaId:any) => {
    try {
      if (fetchedGaId) {
        // Append the Google Analytics script
        const script1 = document.createElement('script');
        script1.async = true;
        script1.src = `https://www.googletagmanager.com/gtag/js?id=${fetchedGaId}`;
        document.body.appendChild(script1);

        // Append the gtag function script
        const script2 = document.createElement('script');
        script2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${fetchedGaId}');
        `;
        document.body.appendChild(script2);
      }
    } catch (error) {
      console.error('Error fetching Google Analytics ID:', error);
    }
  };

  

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<AppLayout tabName={""} globalMenu={globalMenu} />}
        />
        <Route
          path="/product/:id"
          element={<AppLayout tabName={"detail"} globalMenu={globalMenu} />}
        />
        <Route
          path="/product/preview/:id"
          element={<AppLayout tabName={"detail"} globalMenu={globalMenu} />}
        />
        <Route
          path="/dataSheetList/list.pdf"
          element={
            <ListViewFrame
              store={getDataFromLocalStorage}
            />
          }
        />
        <Route path="/dataSheet/:id" element={<ProductDetailPdf />} />
        <Route path="/dataSheet/preview/:id" element={<ProductDetailPreviewPdf />} />
        <Route
          path="/privacy"
          element={<AppLayout tabName={"privacy"} globalMenu={globalMenu} />}
        />
        <Route
          path="/imprint"
          element={<AppLayout tabName={"imprint"} globalMenu={globalMenu} />}
        />
        <Route
          path="/disclaimer"
          element={<AppLayout tabName={"disclaimer"} globalMenu={globalMenu} />}
        />
        <Route
          path="/inspiration/:id"
          element={
            <AppLayout tabName={"inspiration"} globalMenu={globalMenu} />
          }
        />
        <Route
          path="/inspiration/preview/:id"
          element={
            <AppLayout tabName={"inspiration"} globalMenu={globalMenu} />
          }
        />
        <Route
          path="/inspiration"
          element={
            <AppLayout tabName={"inspiration"} globalMenu={globalMenu} />
          }
        />
        <Route path="*" element={<Navigate to="/error" />} />
        <Route
          path="/error"
          element={<AppLayout tabName={"error"} globalMenu={globalMenu} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
