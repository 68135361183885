import {baseURL} from ".";

export const getApiCall = async (endPoints:string) => {
    try {
        const response = await baseURL.get(endPoints);
        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            throw new Error('API request failed');
        }
    } catch (error) {
        throw error;
    }
};

