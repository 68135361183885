import Axios, { AxiosInstance } from 'axios';
import { authToken, baseUrl } from '../utils/constants';

const services = (service: AxiosInstance) => {
  service.interceptors.request.use(
    async (config:any) => {
      try {
        const token = sessionStorage.getItem('token') ?? authToken;
        config.headers['Authorization'] = token ? `Bearer ${token}` : '';
        return config;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    (error:any) => { 
      return Promise.reject(error);
    },
  );
  service.interceptors.response.use(
    (resp: any) => {      
      return resp;
    },
    (error: any) => { 
      if (error?.response?.status === 401) {
        window.location.href = '/login'
      }
      return Promise.reject(error);
    },
  );
};


export const baseURL = Axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});
services(baseURL);


