// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --borderGrey: #c4c4c4;
  --lightGreen: rgb(6, 178, 25);
  --skyBlue: rgb(14, 209, 183);
  --pink: rgb(189, 65, 175);
  --white: rgb(253, 253, 253);

  --primary_cl: #005da9;
  --prime_gradiant: #04b2e2;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--borderGrey);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursor {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,6BAA6B;EAC7B,4BAA4B;EAC5B,yBAAyB;EACzB,2BAA2B;;EAE3B,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,mCAAmC;AACrC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":[":root {\n  --borderGrey: #c4c4c4;\n  --lightGreen: rgb(6, 178, 25);\n  --skyBlue: rgb(14, 209, 183);\n  --pink: rgb(189, 65, 175);\n  --white: rgb(253, 253, 253);\n\n  --primary_cl: #005da9;\n  --prime_gradiant: #04b2e2;\n}\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: var(--borderGrey);\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n.cursor {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
