// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/.FrutigerRegular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/.FrutigerBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/.FrutigerBlackCondenssed.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Frutiger";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  /* Add additional font weights/styles as neededed */
}

body {
  font-family: "Frutiger", sans-serif;
  background-color: #ffffff;
}
.literal-1 {
  color: var(--lightGreen);
}
.literal-2 {
  color: var(--pink);
}
.literal-3 {
  color: var(--skyBlue);
}
.literal-default {
  color: #000 !important;
}
.literal-defaultBg {
  background-color: #00ABD2 !important;
}
.disabiled {
  opacity: 0.5;
  pointer-events: "none";
  cursor: default !important;
}
.porell {
  color: #9fef8a;
}
.lightGray {
  color: rgb(153, 153, 153);
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 999;
}
.coats_link {
  width: 100%;
  margin-top: 16px;
  line-height: 2;
  letter-spacing: 3px;
  text-transform: uppercase;
  padding: 0px 10px;
  margin-bottom: 0.5rem;
  text-align: center;
  position: relative;
  font-size: 12px;
}
.coats_link:hover {
  cursor: pointer;
  color: var(--primary_cl);
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,+DAAkE;EAClE,+DAA+D;EAC/D,+DAA0E;EAC1E,mDAAmD;AACrD;;AAEA;EACE,mCAAmC;EACnC,yBAAyB;AAC3B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,oCAAoC;AACtC;AACA;EACE,YAAY;EACZ,sBAAsB;EACtB,0BAA0B;AAC5B;AACA;EACE,cAAc;AAChB;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,2BAA2B;EAC3B,YAAY;AACd;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,mBAAmB;EACnB,yBAAyB;EACzB,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,eAAe;EACf,wBAAwB;AAC1B","sourcesContent":["@font-face {\n  font-family: \"Frutiger\";\n  src: url(\"./assets/fonts/.FrutigerRegular.ttf\") format(\"truetype\");\n  src: url(\"./assets/fonts/.FrutigerBold.ttf\") format(\"truetype\");\n  src: url(\"./assets/fonts/.FrutigerBlackCondenssed.ttf\") format(\"truetype\");\n  /* Add additional font weights/styles as neededed */\n}\n\nbody {\n  font-family: \"Frutiger\", sans-serif;\n  background-color: #ffffff;\n}\n.literal-1 {\n  color: var(--lightGreen);\n}\n.literal-2 {\n  color: var(--pink);\n}\n.literal-3 {\n  color: var(--skyBlue);\n}\n.literal-default {\n  color: #000 !important;\n}\n.literal-defaultBg {\n  background-color: #00ABD2 !important;\n}\n.disabiled {\n  opacity: 0.5;\n  pointer-events: \"none\";\n  cursor: default !important;\n}\n.porell {\n  color: #9fef8a;\n}\n.lightGray {\n  color: rgb(153, 153, 153);\n}\n\n.blur-background {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  backdrop-filter: blur(10px);\n  z-index: 999;\n}\n.coats_link {\n  width: 100%;\n  margin-top: 16px;\n  line-height: 2;\n  letter-spacing: 3px;\n  text-transform: uppercase;\n  padding: 0px 10px;\n  margin-bottom: 0.5rem;\n  text-align: center;\n  position: relative;\n  font-size: 12px;\n}\n.coats_link:hover {\n  cursor: pointer;\n  color: var(--primary_cl);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
